import React, { useState } from "react";
import SignUp from "./SignUp";
import "../css/header.css";
import { Link } from "react-router-dom";

const Header = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [view, setView] = useState("signIn");
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return  (
    <header className="header">
      <Link to="/" style={{ textDecoration: 'none', color: 'white' , fontSize: 26}}>
          TrimlyXpert
        </Link>

      <nav className="nav">
        <Link to="/">About Us</Link>
        <Link to="/features">Features</Link>
        <Link to="/pricing">Pricing</Link>
        <Link to="/testimonials">Testimonials</Link>
        <Link to="/casestudies">Case Studies</Link>
      </nav>
      <div className="auth-buttons">
        <button className="open-modal-btn" onClick={openModal}>
          Sign In
        </button>
        {isModalOpen && <SignUp closeModal={closeModal} view={view} setView={setView} />}
      </div>
    </header>
  );
};

export default Header;