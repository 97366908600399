import React, { useState } from "react";
import "../css/signUp.css";

function SignUp({ closeModal, view, setView }) {
  const [formData, setFormData] = useState({
    email: "",
    phone_number: "",
    password: "",
    password_confirmation: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (view === "signIn") {
      console.log("Sign In Data", formData);
    } else {
      console.log("Sign Up Data", formData);
    }
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <button className="close-btn" onClick={closeModal}>
          &times;
        </button>

        {view === "signIn" ? (
          <div>
            <h2>Sign In</h2>
            <form onSubmit={handleSubmit}>
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="E-mail"
                required
              />
              <label htmlFor="password">Password:</label>
              <input
                type="password"
                id="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                placeholder="Password"
                required
              />
              <button type="submit" className="submit-btn">
                Log In
              </button>
            </form>
            <p>
              Don't have an account? {" "}
              <span className="toggle-link" onClick={() => setView("signUp")}>
                Sign Up
              </span>
            </p>
          </div>
        ) : (
          <div>
            <h2>Sign Up</h2>
            <form onSubmit={handleSubmit}>
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="E-mail"
                required
              />
              <label htmlFor="phoneNumber">Phone Number:</label>
              <input
                type="tel"
                id="phoneNumber"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
                placeholder="Phone Number"
                required
              />
              <label htmlFor="password">Password:</label>
              <input
                type="password"
                id="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                placeholder="Password"
                required
              />
              <label htmlFor="password">Password Confirmation:</label>
              <input
                type="password"
                id="password"
                name="password"
                value={formData.passwordConfirmation}
                onChange={handleChange}
                placeholder="Password"
                required
              />
              <button type="submit" className="submit-btn">
                Sign Up
              </button>
            </form>
            <p>
              Already have an account? {" "}
              <span className="toggle-link" onClick={() => setView("signIn")}>
                Sign In
              </span>
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

export default SignUp;
