import React from 'react';
import features from '../img/features.jpeg';
import "../css/features.css";

const Features = () => {
  return (
    <section className="features">
      <div className="features-header">
        <div className="text-content">
          <h2>More than just a booking system</h2>
          <p>
            With TrimlyXpert, manage your barbershop effortlessly—track appointments, monitor customer satisfaction, 
            and take your grooming services to the next level.
          </p>

          <div className="stats">
            <div className="stat-item">8000+ Happy Customers</div>
            <div className="stat-item">340+ Partner Barbershops</div>
            <div className="stat-item">500+ Expert Stylists</div>
          </div>
        </div>

        
        <div className="image-container">
          <img src={features} alt="Barber service" className="barber-image" />
        </div>
      </div>

      <div className="feature-cards">
        <div className="feature-card">
          <h3>Enhance Customer Experience</h3>
          <p>Provide smooth bookings, personalized services, and special offers.</p>
        </div>
        <div className="feature-card">
          <h3>Track Appointments</h3>
          <p>Monitor bookings and customer feedback in real-time.</p>
        </div>
        <div className="feature-card">
          <h3>Manage on the Go</h3>
          <p>Control schedules and staff availability anytime, anywhere.</p>
        </div>
      </div>
    </section>
  );
};

export default Features;