import React, { useState, useEffect } from "react";
import Features from "./Features";
import Pricing from "./Pricing";
import Testimonials from "./Testimonials";
import barber from "../img/barber-shop-decor-ideas.jpg";
import download from "../img/CBS-Carousel-2023-002.jpg";
import Imagess from "../img/DSC00213+small.png";
import CaseStudies from "./CaseStudies";
import "../css/hero.css";

const images = [barber, download, Imagess];

const HeroSection = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <section className="hero">
      <div className="hero-image-container">
        <img
          src={images[currentImageIndex]}
          alt={`Slide ${currentImageIndex + 1}`}
          className="slide-image"
        />
        <div className="overlay">
          <h1>Transform Your Business with Ease and Style!</h1>
          <p>
            Elevate your store's potential using TrimlyXpert’s all-in-one
            management system—where efficiency meets excellence. Take control,
            grow faster, and stand out from the crowd!
          </p>
          <button className="get-started-btn">Get Started</button>
        </div>
      </div>

      <Features />
      <Pricing />
      <Testimonials />
      <CaseStudies />
    </section>
  );
};

export default HeroSection;
