import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './Components/Pages/Header';
import HeroSection from './Components/Pages/HeroSection';
import Features from './Components/Pages/Features';
import Pricing from './Components/Pages/Pricing';
import Testimonials from './Components/Pages/Testimonials';
import Footer from './Components/Pages/Footer';
import './App.css';
import Privacy from './Components/Pages/Privacy';
import CaseStudies from './Components/Pages/CaseStudies';

const App = () => {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes> 
          <Route path="/" element={<HeroSection />} />
          <Route path="/features" element={<Features />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/testimonials" element={<Testimonials />} />
          <Route path="/casestudies" element={<CaseStudies />} />
          <Route path="/privacy" element={<Privacy />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
};

export default App;